import React, { Component } from 'react';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';

export class WishlistItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            newTag: '',
            wishlistItem: {
                Id: '',
                Name: '',
                CategoryTags: [],
                Vendors: [],
                InterestLevel: 0
            }
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.addNewCategoryTag = this.addNewCategoryTag.bind(this);
        this.handleTagChange = this.handleTagChange.bind(this);
        this.removeCategoryTag = this.removeCategoryTag.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleNameChange(event) {
        event.persist();

        this.setState(prevState => {
            let wishlistItem = Object.assign({}, prevState.wishlistItem);
            wishlistItem.Name = event.target.value;
            return { wishlistItem };
        });
    }

    addNewCategoryTag() {
        let item = this.state.wishlistItem;
        item.CategoryTags.push(this.state.newTag);
        this.setState({ wishlistItem: item });
    }

    handleTagChange(event) {
        this.setState({ newTag: event.target.value });
    }

    removeCategoryTag(tag) {
        let item = this.state.wishlistItem;
        item.CategoryTags = [...item.CategoryTags.filter(t => t !== tag)];
        this.setState({ wishlistItem: item });
    }

    handleSubmit(event) {
        console.log('Submitted wishlist item: ');
        console.log(this.state.wishlistItem);
        event.preventDefault();
    }

    componentDidMount() {
        if (this.props.location.wishlistItem) {
            //console.log(this.props.location.wishlistItem);
            this.setState({ wishlistItem: this.props.location.wishlistItem });
        }
        //else
        //    console.log('No wishlist item passed.')
    }

    render() {
        return (
            <div className='wishlist-item'>
                <h2>View/Edit Wishlist Item</h2>
                <Form className='wishlist-item-form' onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="itemId">ID</Label>
                        <Input type="text" name="itemId" id="itemId" value={this.state.wishlistItem.Id || ''} disabled></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="itemName">Name</Label>
                        <Input type="text" name="itemName" id="itemName" value={this.state.wishlistItem.Name || ''} onChange={this.handleNameChange}></Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="itemCategory">Category Tags</Label>
                        <ul>
                            {this.state.wishlistItem.CategoryTags.map(ct =>
                                <li key={ct}>
                                    {ct}
                                    <Button onClick={this.removeCategoryTag.bind(this, ct)}>X</Button>
                                    </li>
                            )}
                        </ul>
                        <Input type="text" name="itemCategory" id="itemCategory" value={this.state.newTag} onChange={this.handleTagChange}></Input>
                        <Button onClick={this.addNewCategoryTag}>Add Category</Button>
                    </FormGroup>
                    <FormGroup>
                        <Label for="itemVendors">Vendors</Label>
                        <ul>
                            {this.state.wishlistItem.Vendors.map(v =>
                                <li key={v.VendorName}>{v.VendorName} - {v.VendorUrl} - {v.VendorPrice}</li>
                            )}
                        </ul>
                    </FormGroup>
                    <Button type="submit">Save</Button>
                </Form>
            </div>
        );
    }
}