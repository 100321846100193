import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { Link } from 'react-router-dom';

export class WishlistItems extends Component {

    state = {
        wishlistItems: [],
        filteredWishlistItems: []
    }

    componentDidMount() {
        fetch('https://wishlistservice-ez5tdfo5rq-uc.a.run.app/api/wishlist')
            .then(res => res.json())
            .then(wlItems => this.setState({ wishlistItems: wlItems, filteredWishlistItems: wlItems.sort((wl1, wl2) => { if (wl1.Vendors[0].VendorPrice > wl2.Vendors[0].VendorPrice) return 1; else return -1; }) }));
    }

    priceScale(price) {
        if (price < 20.00)
            return '💰';
        else if (price < 50.00)
            return '💰💰';
        else if (price < 75.00)
            return '💰💰💰';
        else if (price < 100.00)
            return '💰💰💰💰';
        else
            return '💰💰💰💰💰';

    }

    render() {
        return (
            <div>
                <h2>Jeff's Wishlist</h2>

                <Table id="wishListItemsTable" className="wishListItemsTable" responsive hover striped size="sm" style={{ marginTop: '1rem' }}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Category</th>
                            <th>Seller(s)</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.filteredWishlistItems.map(wlItem =>
                            <tr key={wlItem.Id}>
                                <td>{wlItem.Name}</td>
                                {/*<td><Link to={{ pathname: '/wishlistitem', wishlistItem: wlItem }}>{wlItem.Name}</Link></td>*/}
                                {/*<td>{wlItem.CategoryTags[0]}</td>*/}
                                <td>{wlItem.CategoryTags.map((tag, index) => <span key={wlItem.Id + '_' + index}>{tag}{index < wlItem.CategoryTags.length - 1 ? ', ' : ''}</span>)}</td>
                                <td><a target="_blank" rel="noopener noreferrer" href={wlItem.Vendors[0].VendorUrl}>{wlItem.Vendors[0].VendorName}</a> (${wlItem.Vendors[0].VendorPrice.toFixed(2)} {this.priceScale(wlItem.Vendors[0].VendorPrice)})</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

            </div>
        );
    }
}