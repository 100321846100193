import React, { Component } from 'react';
import '../../styles/SideNav.css';
import { Link } from 'react-router-dom';

export class SideNav extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navLogoText: "Jeff's List",
            wishlistNavText: "Wishlist",
            themeNavText: "Theme",
            selectedTheme: "light"
        }

        this.toggleTheme = this.toggleTheme.bind(this);
    }

    toggleTheme() {
        if (this.state.selectedTheme === "dark") {
            document.documentElement.style.setProperty('--site-palette-1', '#ffffff');
            document.documentElement.style.setProperty('--site-palette-2', '#f7fbfc');
            document.documentElement.style.setProperty('--site-palette-3', '#b9d7ea');
            document.documentElement.style.setProperty('--site-palette-4', '#22577a');
            document.documentElement.style.setProperty('--site-palette-5', '#503047');
            document.documentElement.style.setProperty('--icon-primary', '#99ccff');
            document.documentElement.style.setProperty('--icon-secondary', '#22577a');
            document.getElementById('wishListItemsTable').classList.remove('table-dark');

            this.setState({ selectedTheme: "light" });
        }
        else {
            document.documentElement.style.setProperty('--site-palette-5', '#ffffff');
            document.documentElement.style.setProperty('--site-palette-4', '#d2d4c8');
            document.documentElement.style.setProperty('--site-palette-3', '#bf4342');
            document.documentElement.style.setProperty('--site-palette-2', '#6c757d');
            document.documentElement.style.setProperty('--site-palette-1', '#1a1d20');
            document.documentElement.style.setProperty('--icon-primary', '#bf4342');
            document.documentElement.style.setProperty('--icon-secondary', '#8c1c13');
            document.getElementById('wishListItemsTable').classList.add('table-dark');

            this.setState({ selectedTheme: "dark" });
        }
    }

    render() {
        return (
            <nav className="sidenav">
                <ul className="sidenav-nav">
                    <li className="logo">
                        <Link className="sidenav-link" to="/">
                            <span className="link-text logo-text">{this.state.navLogoText}</span>
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fad"
                                data-icon="angle-double-right"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 448 512"
                                className="svg-inline--fa fa-angle-double-right fa-w-14 fa-5x"
                            >
                                <g className="fa-group">
                                    <path
                                        fill="currentColor"
                                        d="M224 273L88.37 409a23.78 23.78 0 0 1-33.8 0L32 386.36a23.94 23.94 0 0 1 0-33.89l96.13-96.37L32 159.73a23.94 23.94 0 0 1 0-33.89l22.44-22.79a23.78 23.78 0 0 1 33.8 0L223.88 239a23.94 23.94 0 0 1 .1 34z"
                                        className="fa-secondary"
                                    ></path>
                                    <path
                                        fill="currentColor"
                                        d="M415.89 273L280.34 409a23.77 23.77 0 0 1-33.79 0L224 386.26a23.94 23.94 0 0 1 0-33.89L320.11 256l-96-96.47a23.94 23.94 0 0 1 0-33.89l22.52-22.59a23.77 23.77 0 0 1 33.79 0L416 239a24 24 0 0 1-.11 34z"
                                        className="fa-primary"
                                    ></path>
                                </g>
                            </svg>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="sidenav-link" to="/">
                            <svg
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fad"
                                data-icon="alien-monster"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 576 512"
                                className="svg-inline--fa fa-alien-monster fa-w-18 fa-9x"
                            >
                                <g className="fa-group">
                                    <path
                                        fill="var(--ci-secondary-color, currentColor)"
                                        d="M456,130H56a24,24,0,0,0-24,24V458a24,24,0,0,0,24,24H456a24,24,0,0,0,24-24V154A24,24,0,0,0,456,130Z"
                                        className="fa-secondary"
                                        opacity="var(--ci-secondary-opacity, 0.75)">
                                    </path>
                                    <path
                                        fill="var(--ci-primary-color, currentColor)"
                                        d="M200,172H312A77,77,0,1,0,256,42.233,76.965,76.965,0,1,0,200,172Zm77-45V95a34.979,34.979,0,1,1,35,35H277ZM200,60a35.04,35.04,0,0,1,35,35v35H200a35,35,0,0,1,0-70Z"
                                        className="fa-primary">
                                    </path>
                                    <polygon
                                        fill="var(--ci-primary-color, currentColor)"
                                        points="32 226 32 290 224 290 224 482 288 482 288 290 480 290 480 226 32 226"
                                        className="fa-primary">
                                    </polygon>
                                </g>
                            </svg>
                            <span className="link-text">{this.state.wishlistNavText}</span>
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="sidenav-link" onClick={this.toggleTheme}>
                            <svg
                                className="theme-icon"
                                id="lightIcon"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fad"
                                data-icon="moon-stars"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <g className="fa-group">
                                    <path
                                        fill="currentColor"
                                        d="M320 32L304 0l-16 32-32 16 32 16 16 32 16-32 32-16zm138.7 149.3L432 128l-26.7 53.3L352 208l53.3 26.7L432 288l26.7-53.3L512 208z"
                                        className="fa-secondary"
                                    ></path>
                                    <path
                                        fill="currentColor"
                                        d="M332.2 426.4c8.1-1.6 13.9 8 8.6 14.5a191.18 191.18 0 0 1-149 71.1C85.8 512 0 426 0 320c0-120 108.7-210.6 227-188.8 8.2 1.6 10.1 12.6 2.8 16.7a150.3 150.3 0 0 0-76.1 130.8c0 94 85.4 165.4 178.5 147.7z"
                                        className="fa-primary"
                                    ></path>
                                </g>
                            </svg>
                            <span className="link-text">{this.state.themeNavText}</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        );
    }
}
